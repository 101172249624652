<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1">
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
        <v-checkbox hide-details class="ml-3 mt-0" v-model="without_accounting" label="Бух обліку відключено" color="secondary"/>
      </div>
      <div class="document-header">
        <div class="block-divider">
          Шапка документу
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="operation"
                :import-getter="{ name: 'cash_order_document_income_operation' }"
                :input-value-as-value="false"
                :disabled="without_accounting"
                clearable
                label="Операція: "
                @selectChange="onOperationChange"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <number_input label="Сума" v-model="sum" v-decimal/>
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 316px; margin-right: 16px">
            <select_input
                v-model="chart_of_account_id"
                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                :computed-getter="'get_chart_of_accounts_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Рахунок (301-302)"
                :show-select-on-focus="false"
                :action-filter="'@field=type@;@start-with=S@301@@@;@field=type@;@start-with=S@БНК@@@;@field=type@;@start-with=S@302'"
                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@301@@@;@field=code@;@start-with=S@302',
                                show: true
                              }"
                :disabled="without_accounting"
            />
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="cash_flow_item_id"
                :computed-action="'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS'"
                :computed-getter="'get_cash_flow_item_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Стаття руху грошових коштів: "
                :select-button-object="{
                                name: 'CASH_FLOW_ITEM_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список статей руху грошових коштів',
                                element_title: 'Стаття руху грошових коштів',
                                show: true
                              }"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <select_input
                v-model="currency_id"
                :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                :computed-getter="'get_currency_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Валюта: "
                :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1"
             v-if="operation === 'pay_from_customer'
             || operation === 'return_pay_from_provider'
             || operation === 'other_pay_from_contractor'"
        >
          <div style="flex: 0 0 316px; margin-right: 16px">
            <select_input
                v-model="contractor_id"
                :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Платник"
                :action-props="{currency_id: currency_id, date: date}"
                :show-additional-field="true"
                :show-select-on-focus="false"
                :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {currency_id: currency_id, date: date},
                                showAdditionalField: true
                              }"
            />
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="contract_id"
                :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_contract_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :owner_id="contractor_id"
                label="Договір"
                :action-props="{owner_id: contractor_id}"
                :show-select-on-focus="false"
                :sync-action-props="true"
                :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px" v-if="!isNew">
            <string_input v-model="order_number" label="№ ордеру"></string_input>
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 632px;">
            <textarea_input v-model="comment" label="Коментар: " :required="false" />
          </div>
        </div>
        <div>
          <v-tabs class="document-small-tabs" color="success">
            <v-tab>
              Бух.облік
            </v-tab>
            <v-tab>
              Друк
            </v-tab>

            <v-tab-item class="pt-2">
              <div class="document-body">
                <div class="document-body-row px-3 pt-2 pb-1 grey lighten-5 d-flex">
                  <div style="flex: 0 0 316px; margin-right: 16px">
                    <select_input
                        v-model="pay_chart_of_account_id"
                        :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                        :computed-getter="'get_chart_of_accounts_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        :label="pay_chart_of_account_label"
                        :show-select-on-focus="false"
                        :action-filter="getActionFilterForChart('type')"
                        :action-props="{by_bank: true}"
                        @selectChange="afterPayChartAccountChange"
                        :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: getActionFilterForChart('code')
                              }"
                        :disabled="without_accounting"
                    />
                  </div>
                  <div style="flex: 0 0 316px;" v-if="operation === 'return_from_salary_person'">
                    <select_input
                        v-model="accrual_group"
                        :import-getter="{ name: 'accrual_group_select' }"
                        :input-value-as-value="false"
                        label="Категорія нарахування: "
                    />
                  </div>
                </div>
                <div class="document-body-row px-3 pt-0 pb-1 grey lighten-5">
                  <div v-if="pay_chart_of_account_id &&
                  (operation === 'other_pay_income'
                  || operation === 'return_from_account_person'
                  || operation === 'cash_get_from_bank'
                  || operation === 'return_from_salary_person')">
                    <div v-if="account_detail_1_type" class="py-3" style="width: 316px;">
                      <select_input
                          v-model="account_detail_1_value"
                          :computed-action="subconto_type_select_setting[account_detail_1_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_1_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="subconto_type_select_setting[account_detail_1_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"
                          @selectChange="onAccountDetailTypeChange"
                      />
                    </div>
                    <div v-if="account_detail_2_type" class="py-1" style="width: 316px;">
                      <select_input
                          v-model="account_detail_2_value"
                          :computed-action="subconto_type_select_setting[account_detail_2_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_2_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :owner_id="account_detail_2_type === 'contract' ? account_detail_1_value : null"
                          :action-props="account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null"
                          :sync-action-props="account_detail_2_type === 'contract'"
                          :label="subconto_type_select_setting[account_detail_2_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_2_type].select_button_object.element_title,
                                show: true,
                                action_props: account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null,
                                sync_action_props: account_detail_2_type === 'contract'
                              }"
                      />
                    </div>
                    <div v-if="account_detail_3_type" class="py-1" style="width: 316px;">
                      <select_input
                          v-model="account_detail_3_value"
                          :computed-action="subconto_type_select_setting[account_detail_3_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_3_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="subconto_type_select_setting[account_detail_3_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item class="pt-2">
              <div class="document-header-row">
                <div style="display: flex; width: 100%;" class="px-3 pt-2">
                  <div style="flex: 0 0 300px; margin-right: 16px">
                    <select_input
                        v-model="document_person_id"
                        :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                        :computed-getter="'get_personal_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        label="Видати: "
                        :show-select-on-focus="false"
                        :required="false"
                        :select-button-object="{
                          name: 'PERSONAL_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список працівників',
                          element_title: 'Працівник',
                          show: true
                        }"
                    />
                  </div>
                  <div style="flex: 0 0 260px; margin-right: 16px">
                    <string_input v-model="document_reason" label="Підстава: " :required="false"></string_input>
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 0 592px;" class="pt-3 pb-2 pl-3">
                    <string_input v-model="document_additional" label="Додаток: " :required="false"></string_input>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <document_print v-model="show_print"
                      :doc_type="'DOCUMENT_CASH_ORDER_INCOME'"
                      :doc_id="itemId"
                      :doc_form="'v1'"
      ></document_print>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
        <v-icon color="teal">mdi-printer</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
  UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
  REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_type_select_setting} from '@/utils/accounting'
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account"
import cashOrderIncomeAPI from "@/utils/axios/accounting/documents/cash_order_income";
import actWorkPerformedOutcome from "@/utils/axios/accounting/documents/act_work_performed_outcome";
import invoiceWarehouseOutcome from "@/utils/axios/accounting/documents/invoice_warehouse_outcome";
import billOutcome from "@/utils/axios/accounting/documents/bill_outcome";
import {format_date_from_python} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component_wto";

const modalDeleteId = 'cash_order_income_account_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_CashOrderIncome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    textarea_input: () => import("@/components/accounting/input/document_text_area_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      subconto_type_select_setting,
      operation: this.item.operation || null,
      contractor_id: this.item.contractor_id || null,
      contract_id: this.item.contract_id || null,
      without_accounting: this.item.without_accounting || false,
      sum: this.item.sum || 0,
      currency_id: this.item.currency_id || null,
      comment: this.item.comment || null,
      order_number: this.item.order_number || null,
      document_reason: this.item.document_reason || null,
      document_person_id: this.item.document_person_id || null,
      document_additional: this.item.document_additional || null,
      accrual_group: this.item.accrual_group || null,
      cash_flow_item_id: this.item.cash_flow_item_id || null,
      pay_chart_of_account_id: this.item.pay_chart_of_account_id || null,
      chart_of_account_id: this.item.chart_of_account_id || null,
      account_detail_1_value: this.item.account_detail_1_value,
      account_detail_1_type: this.item.account_detail_1_type,
      account_detail_2_value: this.item.account_detail_2_value,
      account_detail_2_type: this.item.account_detail_2_type,
      account_detail_3_value: this.item.account_detail_3_value,
      account_detail_3_type: this.item.account_detail_3_type,
    }
  },
  methods: {
    doConducted(payload) {
      if (this.isNew) return

      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      this.$store.dispatch(CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    onOperationChange(payload) {
      const value = (payload || {}).value || null

      if (value) {
        if (value === 'pay_from_customer') {
          const chart = (this.charts.find(item => item.type === '361') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
        if (value === 'return_pay_from_provider') {
          const chart = (this.charts.find(item => item.type === '631') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
        if (value === 'return_from_account_person') {
          const chart = (this.charts.find(item => item.type === '3721') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
        if (value === 'return_from_salary_person') {
          const chart = (this.charts.find(item => item.type === '661') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
        if (value === 'cash_get_from_bank') {
          const chart = (this.charts.find(item => item.type === '311') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
        if (value === 'other_pay_from_contractor') {
          const chart = (this.charts.find(item => item.type === '3771') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
      }
    },
    onAccountDetailTypeChange(payload) {
      const value = (payload || {}).value || (payload || {}).id
      if (this.operation === 'return_from_account_person') {
        if (value) {
          this.document_person_id = value
        } else {
          this.document_person_id = null
        }
      }
    },
    afterPayChartAccountChange(payload) {
      this.account_detail_1_value = null
      this.account_detail_1_type = null
      this.account_detail_2_value = null
      this.account_detail_2_type = null
      this.account_detail_3_value = null
      this.account_detail_3_type = null

      if (payload) {

        if ('text' in payload && 'value' in payload) {
          chartOfAccountAPI.get_by_id({item_id: payload.value})
            .then(response => response.data)
            .then(data => {
              const details = data.details || []
              const obj_1 = details.find(i => i.order === 1)
              const obj_2 = details.find(i => i.order === 2)
              const obj_3 = details.find(i => i.order === 3)

              if (obj_1) {
                this.account_detail_1_type = obj_1.detail_type
              }
              if (obj_2) {
                this.account_detail_2_type = obj_2.detail_type
              }
              if (obj_3) {
                this.account_detail_3_type = obj_3.detail_type
              }
            })
        } else {
          const details = payload.details || []
          const obj_1 = details.find(i => i.order === 1)
          const obj_2 = details.find(i => i.order === 2)
          const obj_3 = details.find(i => i.order === 3)

          if (obj_1) {
            this.account_detail_1_type = obj_1.detail_type
          }
          if (obj_2) {
            this.account_detail_2_type = obj_2.detail_type
          }
          if (obj_3) {
            this.account_detail_3_type = obj_3.detail_type
          }
        }

      }
    },
    getActionFilterForChart(field) {
      if (!this.operation) return `@field=${field}@;@start-with=S@000`
      if (this.operation === 'pay_from_customer') {
        return `@field=${field}@;@start-with=S@36@@@;@field=${field}@;@start-with=S@37@@@;@field=${field}@;@start-with=S@РЗР`
      }
      if (this.operation === 'return_pay_from_provider') {
        return `@field=${field}@;@start-with=S@63@@@;@field=${field}@;@start-with=S@68@@@;@field=${field}@;@start-with=S@РЗР`
      }
      if (this.operation === 'other_pay_from_contractor') {
        return `@field=${field}@;@start-with=S@36@@@;@field=${field}@;@start-with=S@37@@@;@field=${field}@;@start-with=S@63@@@;@field=${field}@;@start-with=S@68@@@;@field=${field}@;@start-with=S@РЗР`
      }
      if (this.operation === 'return_from_account_person') {
        return `@field=${field}@;@start-with=S@3721@@@;@field=${field}@;@start-with=S@3722`
      }
      if (this.operation === 'return_from_salary_person') {
        return `@field=${field}@;@start-with=S@661@@@;@field=${field}@;@start-with=S@663`
      }
      if (this.operation === 'cash_get_from_bank') {
        return `@field=${field}@;@start-with=S@31`
      }
      return ''
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'operation': {id: 'operation', text: 'Не заповнена операція'},
        'sum': {id: 'sum', text: 'Не заповнена сума платіжного доручення'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта'},
        'cash_flow_item_id': {id: 'currency_id', text: 'Не заповнена стаття руху грошових коштів'},
        'pay_chart_of_account_id': {id: 'currency_id', text: 'Не заповнений рахунок бух. обліку (311-314)'},
        'chart_of_account_id': {id: 'currency_id', text: 'Не заповнений рахунок бух. обліку (сторінка бух.облік)'}
      }

      if (!this.isNew) {
        header['order_number'] = {id: 'order_number', text: 'Не заповнений № ордеру'}
      }

      if (this.operation === 'pay_from_customer'
          || this.operation === 'return_pay_from_provider'
          || this.operation === 'other_pay_from_contractor') {
        header['contractor_id'] = {id: 'contractor_id', text: 'Не заповнений платник'}
        header['contract_id'] = {id: 'contract_id', text: 'Не заповнений договір платника'}
      }


      if (this.operation === 'return_from_salary_person') {
        header['accrual_group'] = {id: 'accrual_group', text: 'Не заповнена категорія нарахування'}
      }

      if (this.operation === 'return_from_account_person') {
        header['document_person_id'] = {id: 'document_person_id', text: 'Не заповнено одержано від'}
      }

      if (this.operation === 'other_pay_income') {
        if (this.account_detail_1_type) {
          header['account_detail_1_value'] = {
            id: 'account_detail_1_value',
            text: `Не заповнено субконто №1: ${this.subconto_type_select_setting[this.account_detail_1_type].select_button_object.element_title}`
          }
        }

        if (this.account_detail_2_type) {
          header['account_detail_2_value'] = {
            id: 'account_detail_2_value',
            text: `Не заповнено субконто №2: ${this.subconto_type_select_setting[this.account_detail_2_type].select_button_object.element_title}`
          }
        }

        if (this.account_detail_3_type) {
          header['account_detail_3_value'] = {
            id: 'account_detail_3_value',
            text: `Не заповнено субконто №3: ${this.subconto_type_select_setting[this.account_detail_3_type].select_button_object.element_title}`
          }
        }
      }


      const document_table = {
        // 'tax_id': {id: 'tax_id', table: 'Таблиця', text: 'Не заповнена ставка ПДВ'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(document_table, 'Таблиця', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.currency_id = local_data.currency_id || null
      this.operation = local_data.operation || null
      this.contractor_id = local_data.contractor_id || null
      this.contract_id = local_data.contract_id || null
      this.sum = local_data.sum || 0
      this.comment = local_data.comment || ''
      this.accrual_group = local_data.accrual_group || null
      this.order_number = local_data.order_number || null
      this.document_reason = local_data.document_reason || null
      this.document_additional = local_data.document_additional || null
      this.document_person_id = local_data.document_person_id || null
      this.cash_flow_item_id = local_data.cash_flow_item_id || null
      this.pay_chart_of_account_id = local_data.pay_chart_of_account_id || null
      this.chart_of_account_id = local_data.chart_of_account_id || null
      this.account_detail_1_type = local_data.account_detail_1_type || null
      this.account_detail_2_type = local_data.account_detail_2_type || null
      this.account_detail_3_type = local_data.account_detail_3_type || null
      this.account_detail_1_value = local_data.account_detail_1_value || null
      this.account_detail_2_value = local_data.account_detail_2_value || null
      this.account_detail_3_value = local_data.account_detail_3_value || null
      this.without_accounting = local_data.without_accounting || false
    },
    fetch_data_by_id() {
      if (this.isNew) {
        if (this.item.basic_create_id) {
          if (this.item.basic_create_type === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME') {
            actWorkPerformedOutcome.get_document_by_id(this.item.basic_create_id)
                .then(response => response.data)
                .then(data => {
                  this.contractor_id = data.contractor_id
                  this.contract_id = data.contract_id
                  this.tax_enable = data.tax_enable
                  this.tax_in_sum = data.tax_in_sum
                  if (this.tax_enable) {
                    this.tax_id = data.tax_id
                  }
                  this.sum = data.total_result
                  this.tax_sum = data.total_tax
                  this.pay_chart_of_account_id = data.contractor_chart_of_account_id
                })
          }
          if (this.item.basic_create_type === 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME') {
            invoiceWarehouseOutcome.get_document_by_id(this.item.basic_create_id)
                .then(response => response.data)
                .then(data => {
                  this.contractor_id = data.contractor_id
                  this.contract_id = data.contract_id
                  this.tax_enable = data.tax_enable
                  this.tax_in_sum = data.tax_in_sum
                  if (this.tax_enable) {
                    this.tax_id = data.tax_id
                  }
                  this.sum = data.total_result
                  this.tax_sum = data.total_tax
                  this.pay_chart_of_account_id = data.contractor_chart_of_account_id
                })
          }
          if (this.item.basic_create_type === 'DOCUMENT_BILL_OUTCOME') {
            billOutcome.get_document_by_id(this.item.basic_create_id)
                .then(response => response.data)
                .then(data => {
                  this.contractor_id = data.contractor_id
                  this.contract_id = data.contract_id
                  this.tax_enable = data.tax_enable
                  this.tax_in_sum = data.tax_in_sum
                  if (this.tax_enable) {
                    this.tax_id = data.tax_id
                  }
                  this.sum = data.total_result
                  this.tax_sum = data.total_tax
                  this.pay_chart_of_account_id = data.contractor_chart_of_account_id
                })
          }
        }
        return
      }

      cashOrderIncomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.currency_id = data.currency_id || null
            this.operation = data.operation || null
            this.contractor_id = data.contractor_id || null
            this.contract_id = data.contract_id || null
            this.sum = data.sum || 0
            this.comment = data.comment || ''
            this.accrual_group = data.accrual_group || null
            this.order_number = data.order_number || null
            this.document_reason = data.document_reason || null
            this.document_additional = data.document_additional || null
            this.document_person_id = data.document_person_id || null
            this.cash_flow_item_id = data.cash_flow_item_id || null
            this.pay_chart_of_account_id = data.pay_chart_of_account_id || null
            this.chart_of_account_id = data.chart_of_account_id || null
            this.account_detail_1_type = data.account_detail_1_type || null
            this.account_detail_2_type = data.account_detail_2_type || null
            this.account_detail_3_type = data.account_detail_3_type || null
            this.account_detail_1_value = data.account_detail_1_value || null
            this.account_detail_2_value = data.account_detail_2_value || null
            this.account_detail_3_value = data.account_detail_3_value || null
            this.without_accounting = data.without_accounting || false
          })
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.operation = this.item.operation || null
      this.contractor_id = this.item.contractor_id || null
      this.contract_id = this.item.contract_id || null
      this.bank_account_id = this.item.bank_account_id
      this.sum = this.item.sum || 0
      this.currency_id = this.item.currency || null
      this.comment = this.item.comment || null
      this.accrual_group = this.item.accrual_group || null
      this.order_number = this.item.order_number || null
      this.document_additional = this.item.document_additional || null
      this.document_reason = this.item.document_reason || null
      this.document_person_id = this.item.document_person_id || null
      this.cash_flow_item_id = this.item.cash_flow_item_id || null
      this.pay_chart_of_account_id = this.item.pay_chart_of_account_id || null
      this.chart_of_account_id = this.item.chart_of_account_id || null
      this.account_detail_1_value = this.item.account_detail_1_value
      this.account_detail_1_type = this.item.account_detail_1_type
      this.account_detail_2_value = this.item.account_detail_2_value
      this.account_detail_2_type = this.item.account_detail_2_type
      this.account_detail_3_value = this.item.account_detail_3_value
      this.account_detail_3_type = this.item.account_detail_3_type
      this.without_accounting = this.item.without_accounting || false
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення прихідного касового ордеру  № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        operation: this.operation,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        bank_account_id: this.bank_account_id,
        sum: this.sum,
        currency_id: this.currency_id,
        comment: this.comment,
        order_number: this.order_number,
        accrual_group: this.accrual_group,
        document_additional: this.document_additional,
        document_reason: this.document_reason,
        document_person_id: this.document_person_id,
        cash_flow_item_id: this.cash_flow_item_id,
        pay_chart_of_account_id: this.pay_chart_of_account_id,
        without_accounting: this.item.without_accounting || false,
        chart_of_account_id: this.chart_of_account_id,
        account_detail_1_type: this.account_detail_1_type,
        account_detail_2_type: this.account_detail_2_type,
        account_detail_3_type: this.account_detail_3_type,
        account_detail_1_value: this.account_detail_1_value,
        account_detail_2_value: this.account_detail_2_value,
        account_detail_3_value: this.account_detail_3_value,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  },
  computed: {
    pay_chart_of_account_label() {
      switch (this.operation) {
        case 'pay_from_customer':
          return 'Рахунок розрахунків (36, 37 та ін.)'
        case 'return_pay_from_provider':
          return 'Рахунок розрахунків (63, 65 та ін.)'
        case 'return_from_account_person':
          return 'Рахунок бух.обліку (372)'
        case 'return_from_salary_person':
          return 'Рахунок бух.обліку (661, 663)'
        case 'cash_get_from_bank':
          return 'Рахунок бух.обліку (311-314)'
        case 'other_pay_from_contractor':
          return 'Рахунок бух.обліку'
        case 'other_pay_income':
          return 'Рахунок бух.обліку'
      }
      return ''
    },
  },
  created() {
    this.watch_dialog()
  }
}
</script>
